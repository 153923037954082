<template>
<div v-if="!postArrayMobile.error">
	<div class="EntitySingle">
		<Header :noPin="!pinExist"/>
		<div class="px-6 pb-20">
			<h5 class="font-heading text-h5 text-sepiaBlack dark:text-white">{{ postArrayMobile.title }}</h5>
			<p class="font-heading text-subS text-halfColonialWhite dark:text-white font-normal mt-4">{{ postArrayMobile.era_date }}</p>
			<div class="text-center mt-12 dark:text-white">
				<Gallery />
			</div>
			<div id="buttonSett" class="text-center py-6 hidden">
				<Button :text="$t('entity.moreBtn')" buttonType="primary" fullWidth @click="showEntity()"/>
				<Button href="/mobile/qrCode" :text="$t('entity.backBtn')" buttonType="secondary" fullWidth />
			</div>
			<div id="descriptionPart" class="">
				<p class="font-heading text-subS text-sepiaBlack dark:text-halfColonialWhite my-4">{{ $t('entity.description') }}</p>
				<p class="font-body text-bodyM text-sepiaBlack dark:text-white description" v-html="postArrayMobile.description.replace(/\n/g, '<br>')"></p>
			</div>
			<div id="storyPart" v-if="postArrayMobile.story">
				<p class="font-heading text-subS text-sepiaBlack dark:text-halfColonialWhite my-4">{{ $t('entity.story') }}</p>
				<p class="font-body text-bodyM text-sepiaBlack dark:text-white story" v-html="postArrayMobile.story.replace(/\n/g, '<br>')"></p>
			</div>
		</div>
	</div>
</div>
<div v-else>
  <h1>ERROR! ELEMENT NOT FOUND!</h1>
</div>
</template>

<script>

import Header from "@/components/mobile/Header.vue";
import Button from '@/components/Button.vue';
import Gallery from '@/components/Gallery.vue';

export default {
	name: 'EntitySingle',
	components: {
		Header,
		Button,
		Gallery
	},
	props: {
		content: {
		type: Object,
		require: true,
		default: () => {}
		}
	},
	mounted() {
        this.pinCheck();
    },
	 data(){
        return {
            pinExist: false,
        }
    },
	computed: {
		postArrayMobile() {
			return {
				title: this.content?.title,
				text: this.content?.sport_content,
				src: this.content?.img,
				alt: this.content?.alt,
				description: (this.content.description || '' ).replace(/\n/g, '<br>'),
				link: window.location.pathname + '/' + this.content?.id,
				error: this.content?.error,
				entity_id: this.content?.id,
				era_date: this.content?.era_date,
				story: (this.content.story || '' ).replace(/\n/g, '<br>')
			}
		}
  },
	methods: {
		showEntity() {
			document.querySelector("#descriptionPart").classList.remove("hidden");
			document.querySelector("#buttonSett").classList.add("hidden");
		},
		pinCheck() {
            try{
                const result = this.$store.dispatch('pinCheck');
                result.then(res => {
                    if (res && res != '') {
                        this.pinExist = true;
                    } else {
                        this.pinExist = false;
                    }
                }).catch(() => {
                    this.pinExist = false;
                })
            }catch(e){
                this.pinExist = false;
            }
        },
	}
}
</script>

<style scoped>
img {
	margin: 0 auto;
}
</style>